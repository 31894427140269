export const ID_DRUBLATT = 59;
export const ID_ROGGENPARK_HAUS_AB = 84;
export const ID_BONACASA_SMART_LIVING_LOFT = 132;
export const ID_RESIDENZ47 = 134;
export const ID_STRAHMHOF = 403;
export const ID_ESPLANADE = 408;

export default Object.freeze({
  ID_DRUBLATT,
  ID_ROGGENPARK_HAUS_AB,
  ID_BONACASA_SMART_LIVING_LOFT,
  ID_RESIDENZ47,
  ID_STRAHMHOF,
  ID_ESPLANADE,
});
